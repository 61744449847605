<template>
  <div>
    <Breadcrumbs title="Class Schedules"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <div class="m-t-6 col-12 col-md-6">
                  <b-input-group>
                    <b-form-input v-model="filter" placeholder="Type to Search" class="border btn-square" autofocus></b-form-input>
                    <b-input-group-append>
                      <b-button @click="clearSearch" class="btn btn-square border" variant="primary">Clear</b-button>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-button v-show="checkUserPermissions(user,['create_class_schedules'])" @click="showCreateModal" class="btn btn-square border" variant="success">New Class Schedule</b-button>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-button @click="fetchData" class="btn btn-square border" variant="default">Refresh</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div class="m-t-10 col-12 col-md-6 text-md-right">
                  <b-form-group label-cols="9" label="Per Page">
                    <b-form-select class="btn-square border text-center" v-model="perPage" :options="pageOptions"></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <b-row>
                <b-col cols="12" md="6" lg="4">
                  <div class="" >
                    <b-form-datepicker reset-button today-button v-model="from_date" placeholder="From Date"></b-form-datepicker>
                  </div>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                  <b-form-datepicker reset-button today-button v-model="to_date" placeholder="To Date"></b-form-datepicker>
                </b-col>
              </b-row>

              <div class="table-responsive datatable-vue">
                <b-table
                    ref="table"
                    show-empty
                    selected-variant="success"
                    :items="tableRecords"
                    :fields="tableFields"
                    :filter="filter"
                    :current-page="1"
                    :per-page="perPage"
                    @filtered="onFiltered"
                    @row-selected="rowSelected"
                >
                  <template #cell(action)="row" class="text-right">
                    <b-button v-show="checkUserPermissions(user,['update_class_schedules'])" class="btn-square mr-1 ml-1" variant="success" @click="showEditModal(row.index, row.item, $event.target)">Edit</b-button>
                    <b-button v-show="checkUserPermissions(user,['delete_class_schedules'])" class="btn-square" variant="danger" @click="showDeleteModal(row.index, row.item, $event.target)">Remove</b-button>
                  </template>
                </b-table>
              </div>


              <b-row >
                <b-col cols="4">
                  Records: <strong>{{ totalRows }}</strong>
                </b-col>
                <b-col cols="8" class="my-1" v-if="totalRows">
                  <b-pagination
                      align="right"
                      class="my-0"
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      pills
                  ></b-pagination>
                </b-col>
              </b-row>





              <!-- Begin Delete Modal -->
              <b-modal id="delete_modal" title="Delete" cancel-variant="default" ok-variant="danger" ok-title="Confirm" @hide="clearFormModals" @ok="submitDeleteModal">
                <h6 class="m-b-10">Please confirm removal of following record:</h6>
                <strong v-text="selected.name"></strong>
              </b-modal>
              <!-- End Delete Modal -->

              <!-- Begin Create Modal -->
              <b-modal id="form_modal" :title="form_modal_title" cancel-variant="default" ok-variant="primary" :ok-title="form_modal_ok_title" @hide="clearFormModals" @ok="submitFormModal">

                <b-form-group v-if="formModal.id" label="Id">
                  <b-input v-if="form_modal_id === 'form_modal_create'" v-model="formModal.id"></b-input>
                  <b-input v-else v-model="formModal.id" readonly></b-input>
                </b-form-group>

                <div class="row">
                  <div class="col-6">
                    <b-form-group label="Institute *">
                      <b-form-select @change="changedInstitute" v-model="formModal.institute_id" :options="institutes" value-field="id" text-field="text" class="btn-square border">
                        <template #first>
                          <b-form-select-option :value="null" disabled>-- Select an option --</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-6">
                    <b-form-group label="Schedule Type">
                      <b-form-select class="form-control border" v-model="formModal.schedule_type_id" :options="schedule_types" value-field="id" text-field="text">
                        <template #first>
                          <b-form-select-option :value="null" disabled>-- Select an option --</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </div>
                </div>



                <div class="form-group">
                  <label class="col-form-label">Day</label>
                  <template v-if="formModal.schedule_type_id < 2">
                    <b-form-datepicker v-model="formModal.day_date"></b-form-datepicker>
                  </template>
                  <template v-if="formModal.schedule_type_id > 1">
                    <b-form-select class="text outline" v-model="formModal.day_day">
                      <template #first>
                        <option selected>-- Select an option --</option>
                      </template>
                      <b-form-select-option v-for="(dayOtp, index) in dayOptions" :key="index" :value="dayOtp.id" v-text="dayOtp.text"></b-form-select-option>
                    </b-form-select>
                  </template>
                </div>

                <div class="form-row">
                  <div class="col-md-6">
                    <b-form-group label="Start Time">
                      <b-form-timepicker v-model="formModal.start_time"></b-form-timepicker>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group label="End Time">
                      <b-form-timepicker v-model="formModal.end_time"></b-form-timepicker>
                    </b-form-group>
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-form-label">Subject</label>
                  <b-form-select @change="loadTeachers" v-model="formModal.subject_id" :options="subjects" value-field="id" text-field="text" class="border" required>
                    <template #first>
                      <option :value="null" selected>-- Please select an option --</option>
                    </template>
                  </b-form-select>
                </div>

                <div class="form-group">
                  <label class="col-form-label">Teacher</label>
                  <b-form-select :disabled="!teachers.length" @change="loadClassDetails" class="form-control border" v-model="formModal.teacher_id">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                    </template>
                    <b-form-select-option v-for="(teacher, index) in teachers" :key="index" :value="teacher.id" v-text="teacher.text"></b-form-select-option>
                  </b-form-select>
                </div>

                <div class="form-group">
                  <label class="col-form-label">Class</label>
                  <b-form-select :disabled="!class_details.length" class="form-control border" v-model="formModal.class_detail_id" :options="class_details" value-field="id" text-field="text">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                    </template>
                  </b-form-select>
                </div>

                <div class="form-group">
                  <label class="col-form-label">Classroom</label>
                  <b-form-select :disabled="!classrooms.length" class="form-control border" v-model="formModal.classroom_id" :options="classrooms" value-field="id" text-field="text">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                    </template>
                  </b-form-select>
                </div>


              </b-modal>
              <!-- End Create Modal -->



            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {checkUserPermissions} from '@/plugins/functions'
import { mapGetters, mapState } from 'vuex'
import CRUD_API from '@/services/crud'
export default {
  name: "class_schedules",
  computed: {
    ...mapState({ user: state => JSON.parse(state.auth.user) }),
    ...mapGetters({
      institutes: 'select/institutes',
      subjects: 'select/subjects',
      schedule_types: 'select/schedule_types',
    })
  },
  data() {
    return {
      filter: '',
      totalRows: 0,
      perPage: 5,
      currentPage: 1,
      pageOptions: [5,10,25,50,100],
      tableRecords: [],
      selected: [],

      api_base: '/api/backend/pages/class-schedules',
      tableFields: [
        // { key: 'id', label: 'ID', sortable: true, },
        { key: 'institute.name', label: 'Institute', sortable: true, },
        { key: 'class_detail.name', label: 'Class', sortable: true, },
        { key: 'classroom.name', label: 'Classroom', sortable: true, },
        { key: 'day', label: 'Date', sortable: true, },
        { key: 'start_time', label: 'Start Time', sortable: false, },
        { key: 'end_time', label: 'End Time', sortable: false, },
        { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],

      from_date: null,
      to_date: null,

      // Form Modal
      form_modal_id: '',
      form_modal_title: '',
      form_modal_ok_variant: 'primary',
      form_modal_ok_title: '',
      formModal: {
        index: null,
        id: null,
        name: '',
        institute_id: null,
        teacher_id: null,
        subject_id: null,
        class_detail_id: null,
        schedule_type_id: null,
        classroom_id: null,
        recurrence: true,
        start_time: null,
        end_time: null,
        day_date: null,
        day_day: null,
        status: 0
      },

      teachers: [],
      classrooms: [],
      class_details: [],
      statusOptions: [
        { value: 0, text: "Active" },
        { value: 1, text: "Inactive" },
      ],
      dayOptions: [
        { id: 'Sunday', text: 'Sunday' },
        { id: 'Monday', text: 'Monday' },
        { id: 'Tuesday', text: 'Tuesday' },
        { id: 'Wednesday', text: 'Wednesday' },
        { id: 'Thursday', text: 'Thursday' },
        { id: 'Friday', text: 'Friday' },
        { id: 'Saturday', text: 'Saturday' }
      ]
    }
  },
  watch: {
    currentPage() {
      this.fetchData()
    },
    perPage() {
      this.currentPage = 1
      this.fetchData()
    },
    filter() {
      this.currentPage = 1
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  },
  created() {
    this.$store.dispatch('select/fetchRecords', { id: 'institutes' })
    this.$store.dispatch('select/fetchRecords', { id: 'subjects' })
    this.$store.dispatch('select/fetchRecords', { id: 'schedule-types' })
  },
  methods: {
    checkUserPermissions,
    onFiltered(filteredItems) {
      this.currentPage = 1
      this.items.count = filteredItems.length
    },
    rowSelected(item) {
      this.selected = item
    },
    clearSearch() {
      this.filter = ''
      this.currentPage = 1
      this.from_date = null
      this.to_date = null
      this.fetchData()
    },
    handlePageChange(value) {
      //alert(value)
      this.perPage = value
      this.fetchData()
    },
    handlePageSizeChange(value) {
      this.currentPage = value
      this.fetchData()
    },
    async fetchData() {
      this.tableRecords = []
      await CRUD_API.index( this.api_base, {
        url: this.api_base,
        params: {
          per_page: this.perPage,
          page: this.currentPage,
          filter: this.filter,
          from_date: this.from_date,
          to_date: this.to_date
        }
      }).then(function(resp) {
        //this.currentPage = resp.data.data.current_page
        //this.perPage = resp.data.data.per_page
        this.totalRows = resp.data.data.total
        this.tableRecords = resp.data.data.data
      }.bind(this)).catch((err) => {
        console.log(err)
        this.$toasted.error("Error occurred while retrieving data")
      }).finally(() => {
        this.$refs.table.refresh()
      })
    },

    clearFormModals() {
      this.classrooms = []
      this.teachers = []
      this.class_details = []
      this.formModal.index = null
      this.formModal.id = null
      this.formModal.name = ''
      this.formModal.institute_ = null
      this.formModal.teacher_id = null
      this.formModal.subject_id = null
      this.formModal.class_detail_id = null
      this.formModal.schedule_type_id = null
      this.formModal.classroom_id = null
      this.formModal.recurrence = null
      this.formModal.day_date = null
      this.formModal.day_day = null
      this.formModal.start_time = null
      this.formModal.end_time = null
      this.formModal.status = 0
    },
    // Create Record Modal
    showCreateModal(button) {
      this.clearFormModals()
      this.form_modal_title = "New Class Schedule"
      this.form_modal_ok_title = 'Submit'
      this.$root.$emit('bv::show::modal', 'form_modal', button)
    },
    submitFormModal() {
      if (this.formModal.id) {
        this.submitEditModal()
      } else {
        this.submitCreateModal()
      }
    },
    submitCreateModal() {
      CRUD_API.create(this.api_base, {
        status: this.formModal.status,
        institute_id: this.formModal.institute_id,
        teacher_id: this.formModal.teacher_id,
        subject_id: this.formModal.subject_id,
        class_detail_id: this.formModal.class_detail_id,
        schedule_type_id: this.formModal.schedule_type_id,
        classroom_id: this.formModal.classroom_id,
        day_date: this.formModal.day_date,
        day_day: this.formModal.day_day,
        start_time: this.formModal.start_time,
        end_time: this.formModal.end_time,
      }).then((resp) => {
        console.log(resp)
        this.$toasted.success("Class schedule added successfully")
      }).catch((err) => {
        console.log(err)
        this.$toasted.error("Error occurred while saving data")
      }).finally(() => {
        this.fetchData()
      })
    },
    // Edit Record Modal
    async showEditModal(index, item, button) {
      // this.teachers.push({ id: item.teacher.id, text: item.teacher.username })
      await this.class_details.push({ id: item.class_detail.id, text: item.class_detail.name })
      // await CRUD_API.index('/api/backend/select/subjects').then((resp) => {
      //   this.subjects = resp.data.data
      // })
      await CRUD_API.index('/api/backend/select/teachers', {
        params: {
          institute_id: item.institute_id,
          subject_id: item.class_detail.subject_id
        }
      }).then((resp) => {
        this.teachers = resp.data.data
      })
      await CRUD_API.index('/api/backend/select/classrooms', {
        params: {institute_id: item.institute_id,}
      }).then((resp) => {
        this.classrooms = resp.data.data
      })
      // console.log(item)
      this.formModal.index = index
      this.formModal.id = item.id
      this.formModal.name = item.name
      this.formModal.status = item.status
      this.formModal.institute_id = item.institute_id
      this.formModal.subject_id = item.class_detail.subject_id
      this.formModal.teacher_id = item.class_detail.teacher_id
      this.formModal.class_detail_id = item.class_detail_id
      this.formModal.schedule_type_id = item.schedule_type_id
      this.formModal.classroom_id = item.classroom_id
      this.formModal.day_date = item.day
      this.formModal.day_day = item.day
      this.formModal.start_time = item.start_time
      this.formModal.end_time = item.end_time
      this.form_modal_title = "Edit Class Schedule"
      this.form_modal_ok_title = 'Update'
      await this.$root.$emit('bv::show::modal', 'form_modal', button)
    },
    submitEditModal(){
      CRUD_API.update(this.api_base, this.formModal.id, {
        id: this.formModal.id,
        institute_id: this.formModal.institute_id,
        schedule_type_id: this.formModal.schedule_type_id,
        class_detail_id: this.formModal.class_detail_id,
        classroom_id: this.formModal.classroom_id,
        start_time: this.formModal.start_time,
        day_date: this.formModal.day_date,
        day_day: this.formModal.day_day,
        end_time: this.formModal.end_time,
        status: this.formModal.status
      }).then((resp) => {
        console.log(resp.data)
        this.$toasted.success("Class schedule updated successfully")
      }).catch((err) => {
        console.log(err)
        this.$toasted.error("Error occurred while updating data")
      }).finally(() => {
        this.fetchData()
      })
    },
    // Delete Record Modal
    showDeleteModal(index, item, button) {
      this.selected = item
      this.$root.$emit('bv::show::modal', 'delete_modal', button)
    },
    async submitDeleteModal(){
      await CRUD_API.delete(this.api_base, this.selected.id)
          .then(() => {
            this.$toasted.success("Class schedule removed successfully")
          }).catch((err) => {
            console.log(err)
            this.$toasted.error("Error occurred while removing data")
          }).finally(() => {
            this.fetchData()
          })
    },


    loadTeachers() {
      this.teachers = []
      this.formModal.teacher_id = null
      this.class_details = []
      this.formModal.class_detail_id = null
      CRUD_API.index('/api/backend/select/teachers', {
        params: {
          institute_id: this.formModal.institute_id,
          subject_id: this.formModal.subject_id
        }
      }).then((resp) => {
        this.teachers = resp.data.data
      })
    },
    loadClassDetails() {
      this.class_details = []
      this.formModal.class_detail_id = null
      CRUD_API.index('/api/backend/select/classes', {
        params: {
          institute_id: this.formModal.institute_id,
          subject_id: this.formModal.subject_id,
          teacher_id: this.formModal.teacher_id
        }
      }).then((resp) => {
        this.class_details = resp.data.data
      })
    },
    changedInstitute() {
      this.classrooms = []
      CRUD_API.index('/api/backend/select/classrooms', {
        params: { institute_id: this.formModal.institute_id }
      }).then((resp) => {
        this.classrooms = resp.data.data
      })
    }
  }
}
</script>

<style scoped>

</style>
